





















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  filters: {
    twoDigits(value) {
      return value.toString().length <= 1 ? `0${value}` : value;
    },
  },
})
export default class Countdown extends Vue {
  @Prop(Date) endDate;
  @Prop(Boolean) value;

  private timer;

  public days = 0;
  public hours = 0;
  public minutes = 0;
  public seconds = 0;

  updateRemaining(distance) {
    this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
    this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
  }

  tick() {
    const currentTime = new Date();
    const distance = Math.max(this.endDate.getTime() - currentTime.getTime(), 0);
    this.updateRemaining(distance);

    if (distance <= 0) {
      clearInterval(this.timer);
      this.$emit("input", true);
    }
  }

  mounted(): void {
    this.tick();
    this.timer = setInterval(() => {
      this.tick();
    }, 1000);
  }

  destroyed(): void {
    clearInterval(this.timer);
  }
}
