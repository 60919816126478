






































import { Component, Prop, Vue } from 'vue-property-decorator';
import { picasso } from '@vechain/picasso';
import * as common from '@/operations/common';
import * as utils from '@/operations/utils';

import * as blockchainMethod from '@/operations/blockchainMethod';
import AccountSign from '@/types/AccountSign';
import { bus } from '@/main';

@Component({
  watch: {
    tokenList: {
      immediate: true,
      handler(val) {
        (this as BaseClaimWhaleToken).numberOfMint = (this as BaseClaimWhaleToken).numberOfMint || val.length;
      },
    },
  },
})
export default class BaseClaimWhaleToken extends Vue {
  @Prop() tokenList;
  @Prop(Number) price;
  @Prop(Boolean) disabled;
  @Prop(Number) freeCards;

  public numberOfMint = 0;

  get accountSignCompute(): AccountSign {
    return this.$store.getters.account;
  }

  public async claim(event) {
    if (this.disabled) return;

    this.tokenList = this.tokenList.sort((a, b) => (a.tokenId < b.tokenId ? 1 : -1));

    await blockchainMethod
      .claimVehashes(
        this.$connex,
        this.$VIP191SERVICE,
        this.$VIP191PAYER,
        'Claim VeHashes',
        this.$ENV.WOV_COMMUNITY_CARD_NFT,
        this.accountSignCompute.address,
        this.tokenList.slice(0, this.numberOfMint),
        'special',
        utils.humanToEVM(this.price.toString())
      )
      .then((result) => {
        bus.$emit('mintEvent', result.txid);
        bus.$emit('showToast', true);
      });
  }
}
