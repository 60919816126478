


















import { bus } from '@/main';
import { Component, Vue } from 'vue-property-decorator';
import * as readVIP181Core from '../operations/readVIP181Core';

@Component({})
export default class UnclaimedCardProgressBar extends Vue {
  private ticker = this.$connex.thor.ticker();

  public totalSupply = 2085;

  private async updateTotalSupply(): Promise<void> {
    this.totalSupply = await readVIP181Core.getTotalSupply(this.$connex, this.$ENV.WOV_COMMUNITY_CARD_NFT);
  }

  created(): void {
    bus.$on('updateTotalSupply', this.updateTotalSupply);
  }

  private loopBlockchain(): void {
    this.ticker.next().then(() => {
      this.updateTotalSupply();
      this.loopBlockchain();
    });
  }

  mounted(): void {
    this.loopBlockchain();
  }
}
