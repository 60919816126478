



















import { Component, Vue } from 'vue-property-decorator';

import AccountSign from '@/types/AccountSign';

import BaseClaimSaleToken from '../components/claimComponents/BaseClaimSaleToken.vue';
import BaseClaimGenesisToken from '../components/claimComponents/BaseClaimGenesisToken.vue';
import BaseClaimOGToken from '../components/claimComponents/BaseClaimOGToken.vue';
import BaseClaimSpecialToken from '../components/claimComponents/BaseClaimSpecialToken.vue';
import BaseClaimWhaleToken from '../components/claimComponents/BaseClaimWhaleToken.vue';
import BaseClaimAfricaToken from '../components/claimComponents/BaseClaimAfricaToken.vue';
import BaseClaimMoonToken from '../components/claimComponents/BaseClaimMoonToken.vue';
import BaseClaimOlympicToken from '../components/claimComponents/BaseClaimOlympicToken.vue';
import UnclaimedCardProgressBar from '../components/UnclaimedCardProgressBar.vue';
import Countdown from '../components/Countdown.vue';

import * as common from '../operations/common';
import * as blockchainMethod from '../operations/blockchainMethod';
import * as readVIP181Core from '../operations/readVIP181Core';
import TokenProperty from '@/types/TokenProperty';

// uint256 public constant SALE_MINT_PRICE = 2800 * (10**18);
// uint256 public constant OG_MINT_PRICE = 1800 * (10**18);
// uint256 public constant GENESIS_MINT_PRICE = 800 * (10**18);
// uint256 public constant SPECIAL_MINT_PRICE = 0 * (10**18);

// uint8 public constant OG_MINT_NUMBER = 1;
// uint8 public constant WHALE_MINT_NUMBER = 10;
// uint8 public constant AFRICA_MINT_NUMBER = 5;
// uint8 public constant MOON_MINT_NUMBER = 2;

@Component({
  components: {
    BaseClaimSaleToken,
    BaseClaimOGToken,
    BaseClaimSpecialToken,
    BaseClaimWhaleToken,
    BaseClaimAfricaToken,
    BaseClaimMoonToken,
    BaseClaimOlympicToken,
    BaseClaimGenesisToken,
    UnclaimedCardProgressBar,
    Countdown,
  },
})
export default class CreateToken extends Vue {
  public key = 0;

  public isSuccess = false;
  public valid = false;
  public loaded = false;
  public countdownEnded = false;

  private tokenOfOwnerByIndexGenesis = [] as any;
  private genesisArray = [] as any;
  private tokenOfOwnerByIndexSpecial = [] as any;
  private ogArray = [] as any;
  private specialArray = [] as any;
  private whaleArray = [] as any;
  private africaArray = [] as any;
  private moonArray = [] as any;
  private olympicArray = [] as any;
  private tokenOfOwnerByIndexVeHashes = [] as any;
  private myCollectionData: TokenProperty[] = [];
  private exampleAddress = '0x4f2fb58d053a34d7F6cB1066f92884eE834eA21B';

  private SPECIAL_START_TOKENID = 0;
  private WHALE_START_TOKENID = 948;
  private AFRICA_START_TOKENID = 998;
  private MOON_START_TOKENID = 1048;
  private OLYMPIC_START_TOKENID = 1098;
  private WORLDCUP_START_TOKENID = 1148;
  private OG_START_TOKENID = 1448;

  // private SALE_MINT_PRICE = 2800;
  // private OG_MINT_PRICE = 1800;
  // private GENESIS_MINT_PRICE = 800;
  // private SPECIAL_MINT_PRICE = 0;
  private SALE_MINT_PRICE = 2800;
  private OG_MINT_PRICE = 1800;
  private GENESIS_MINT_PRICE = 800;
  private SPECIAL_MINT_PRICE = 0;

  get accountSignCompute(): AccountSign {
    return this.$store.getters.account;
  }

  public async refreshMyGenesisCollection(): Promise<void> {
    const balanceOf = await readVIP181Core.getBalanceOf(
      this.$connex,
      this.$ENV.GENESIS_TOKEN,
      this.accountSignCompute.address
    );
    if (balanceOf > 0) {
      let chunks = this.chunkBy(balanceOf, 100);

      let start = 0;
      let end = 0;
      for (let c = 0; c < chunks.length; c++) {
        end = end + chunks[c];
        const exp = await blockchainMethod.getMyCollection(
          this.$connex,
          this.$ENV.GENESIS_TOKEN,
          this.accountSignCompute.address,
          start,
          end
        );
        await exp
          .gas(200000) // Set maximum gas
          .caller(this.accountSignCompute.address) // Set caller
          .execute()
          .then((outputs) => {
            start = start + parseInt(chunks[c]);
            this.genesisArray = this.genesisArray.concat(outputs);
          });
      }
      this.removeGenesisClaimed();
    }
  }

  public async refreshMySpecialCollection(): Promise<void> {
    const balanceOf = await readVIP181Core.getBalanceOf(
      this.$connex,
      this.$ENV.SPECIAL_TOKEN,
      this.accountSignCompute.address
    );

    if (balanceOf > 0) {
      let chunks = this.chunkBy(balanceOf, 40);

      let start = 0;
      let end = 0;

      //   private SPECIAL_START_TOKENID = 0;
      //   private WHALE_START_TOKENID = 948;
      //   private AFRICA_START_TOKENID = 998;
      //   private MOON_START_TOKENID = 1048;
      //   private OLYMPIC_START_TOKENID = 1098;
      //   private WORLDCUP_START_TOKENID = 1148;
      //   private OG_START_TOKENID = 1448;

      for (let c = 0; c < chunks.length; c++) {
        end = end + chunks[c];
        const exp = await blockchainMethod.getMyCollection(
          this.$connex,
          this.$ENV.SPECIAL_TOKEN,
          this.accountSignCompute.address,
          start,
          end
        );
        await exp
          .gas(200000) // Set maximum gas
          .caller(this.accountSignCompute.address) // Set caller
          .execute()
          .then((outputs) => {
            start = start + parseInt(chunks[c]);
            this.tokenOfOwnerByIndexSpecial = this.tokenOfOwnerByIndexSpecial.concat(outputs);
          });
      }
      this.removeSpecialClaimed();
    }
  }

  public async removeGenesisClaimed(): Promise<void> {
    const tokens = this.genesisArray.reduce((acc, el) => {
      if (el.data) {
        acc.push({
          tokenId: parseInt(el.data, 16),
          category: 0,
        });
      }

      return acc;
    }, []);

    this.tokenOfOwnerByIndexGenesis = [];

    const chunkSize = 100;
    for (let i = 0; i < tokens.length; i += chunkSize) {
      const chunk = tokens.slice(i, i + chunkSize);

      const exp = await blockchainMethod.batchClaimedStatus(
        this.$connex,
        this.$ENV.WOV_COMMUNITY_CARD_NFT,
        chunk
      );
      
      const auctionExp = await blockchainMethod.batchCheckIsOnAuction(
        this.$connex,
        this.$ENV.GENESIS_AUCTION_CONTRACT,
        chunk
      );

      const expResult = await exp
        .gas(200000)
        .caller(this.accountSignCompute.address)
        .execute();

      const auctionExpResult = await auctionExp
        .gas(200000)
        .caller(this.accountSignCompute.address)
        .execute();

      expResult.forEach((el, k) => {
        const auctionEl = auctionExpResult[k];

        if (el.data && auctionEl?.data) {
          const matched = el.data.slice(2).match(/.{1,64}/g);
          const auctionMatched = auctionEl.data.slice(2).match(/.{1,64}/g);

          if (matched && auctionMatched) {
            const claimedStatus = Boolean(parseInt(matched[0], 16));
            const isOnAuction = Boolean(parseInt(auctionMatched[0], 16));

            if (!claimedStatus && !isOnAuction) {
              this.tokenOfOwnerByIndexGenesis.push({
                ...chunk[k],
                claimedStatus
              });
            }
          }
        }
      });
    }
  }

  public async removeSpecialClaimed(): Promise<void> {
    const tokens = this.tokenOfOwnerByIndexSpecial.reduce((acc, el) => {
      if (el.data) {
        const matched = el.data.slice(2).match(/.{1,64}/g);

        if (matched) {
          const tokenId = parseInt(matched[0], 16);
          let category = 0;
          let arrayKey = '';

          if (tokenId >= this.OG_START_TOKENID) {
            category = 4;
            arrayKey = 'ogArray';
          } else if (tokenId >= this.WORLDCUP_START_TOKENID) {
            category = 4;
          } else if (tokenId >= this.OLYMPIC_START_TOKENID) {
            category = 7;
            arrayKey = 'olympicArray';
          } else if (tokenId >= this.MOON_START_TOKENID) {
            category = 3;
            arrayKey = 'moonArray';
          } else if (tokenId >= this.AFRICA_START_TOKENID) {
            category = 6;
            arrayKey = 'africaArray';
          } else if (tokenId >= this.WHALE_START_TOKENID) {
            category = 9;
            arrayKey = 'whaleArray';
          } else if (tokenId >= this.SPECIAL_START_TOKENID) {
            category = 1;
            arrayKey = 'specialArray';
          }

          if (arrayKey) {
            acc.push({
              tokenId,
              category,
              arrayKey,
            });
          }
        }
      }

      return acc;
    }, []);

    const chunkSize = 100;
    for (let i = 0; i < tokens.length; i += chunkSize) {
      const chunk = tokens.slice(i, i + chunkSize);

      const exp = await blockchainMethod.batchClaimedStatus(
        this.$connex,
        this.$ENV.WOV_COMMUNITY_CARD_NFT,
        chunk
      );

      const auctionExp = await blockchainMethod.batchCheckIsOnAuction(
        this.$connex,
        this.$ENV.SPECIAL_AUCTION_CONTRACT,
        chunk
      );

      const expResult = await exp
        .gas(200000)
        .caller(this.accountSignCompute.address)
        .execute();

      const auctionExpResult = await auctionExp
        .gas(200000)
        .caller(this.accountSignCompute.address)
        .execute();

      expResult.forEach((el, k) => {
        const auctionEl = auctionExpResult[k];

        if (el.data && auctionEl?.data) {
          const matched = el.data.slice(2).match(/.{1,64}/g);
          const auctionMatched = auctionEl.data.slice(2).match(/.{1,64}/g);

          if (matched && auctionMatched) {
            const claimedStatus = Boolean(parseInt(matched[0], 16));
            const isOnAuction = Boolean(parseInt(auctionMatched[0], 16));

            if (!claimedStatus && !isOnAuction) {
              const chunkEl = chunk[k];

              this[chunkEl.arrayKey].push({
                tokenId: chunkEl.tokenId,
                category: chunkEl.category,
                claimedStatus,
              });
            }
          }
        }
      });
    }
  }

  public async created() {
    this.refreshMyGenesisCollection();
    this.refreshMySpecialCollection();
  }

  private chunkBy(number, n) {
    var chunks = Array(Math.floor(number / n)).fill(n);
    var remainder = number % n;

    if (remainder > 0) {
      chunks.push(remainder);
    }
    return chunks;
  }
}
