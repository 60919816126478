






































import { Component, Prop, Vue } from 'vue-property-decorator';
import { picasso } from '@vechain/picasso';
import * as common from '@/operations/common';
import * as utils from '@/operations/utils';

import * as blockchainMethod from '@/operations/blockchainMethod';
import AccountSign from '@/types/AccountSign';
import { bus } from '@/main';

@Component({})
export default class BaseClaimOGToken extends Vue {
  @Prop(Number) price;
  @Prop(Boolean) disabled;

  private numberOfMint = 0;

  public accountSign: AccountSign = {
    address: '',
    vetBalance: 0,
    wovBalance: 0,
    wovCommunityBalance: 0,
    signedIn: false,
    approvedCommunityAuction: false,
  };

  get accountSignCompute(): AccountSign {
    return this.$store.getters.account;
  }

  // signAgreement
  public async signAgreement(): Promise<void> {
    blockchainMethod.sign(this.$connex, this.$WEBSITE_URL).then((result) => {
      // console.log(result.annex.signer);
      this.$store.commit('setAccountAddress', result.annex.signer);
      this.accountSign = this.$store.getters.account;
      this.$store.commit('setAccountStatus', true);
      this.$store.dispatch('refreshAccountSign');
      this.$store.dispatch('refreshAccountCommunityNFT');
    });
  }

  public async claim(event) {
    if (this.disabled) return;

    // we do not multiple the price with the number of claim because this is managed at transaction level in blockchainMethods.;
    await blockchainMethod
      .buyVehashes(
        this.$connex,
        this.$VIP191SERVICE,
        this.$VIP191PAYER,
        'Buy VeHashes',
        this.$ENV.WOV_COMMUNITY_CARD_NFT,
        this.accountSignCompute.address,
        this.numberOfMint,
        utils.humanToEVM(this.price.toString())
      )
      .then((result) => {
        bus.$emit('mintEvent', result.txid);
        bus.$emit('showToast', true);
      });
  }
}
